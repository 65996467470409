export default function tableColumns() {
  const oldColumns = [
    {
      thClass: 'text-center',
      label: 'Pemda',
      field: 'instansi',
      filterOptions: {
        enabled: true,
        placeholder: 'Nama Instansi',
      },
    },
    {
      thClass: 'text-center',
      label: 'Nama Kegiatan',
      field: 'nama_kegiatan',
      filterOptions: {
        enabled: true,
        trigger: 'enter',
        placeholder: 'Nama Program',
      },
    },
    {
      thClass: 'text-center',
      label: 'Total Anggaran',
      field: 'total_anggaran',
      type: 'number',
    },
    {
      thClass: 'text-center',
      label: 'Perubahan Terakhir',
      field: 'last_update',
    },
    {
      thClass: 'text-center',
      label: 'Hari Dalam Antrian',
      field: 'wait_time',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Asdep Aplikasi',
      field: 'asdep_app',
      width: '110px',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Asdep Infra',
      field: 'asdep_infra',
      width: '90px',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Takel Aplikasi',
      field: 'takel_app',
      width: '110px',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Takel Infra',
      field: 'takel_infra',
      width: '90px',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'LAIP Aplikasi',
      field: 'laip_app',
      width: '110px',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'LAIP Infra',
      field: 'laip_infra',
      width: '90px',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Aktivitas',
      width: '140px',
      field: 'aktivitas',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Status',
      field: 'status_text',
      width: '140px',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Action',
      field: 'action',
      width: '160px',
      sortable: false,
    },
  ]

  const newColumns = [
    {
      thClass: 'text-center',
      label: 'Pemda',
      field: 'instansi',
      filterOptions: {
        enabled: true,
        placeholder: 'Nama Instansi',
      },
    },
    {
      thClass: 'text-center',
      label: 'Unit',
      field: 'unit_kerja',
      filterOptions: {
        enabled: true,
        placeholder: 'Nama Unit',
      },
    },
    {
      thClass: 'text-center',
      label: 'Nama Pengadaan',
      field: 'nama',
      filterOptions: {
        enabled: true,
        placeholder: 'Nama Pengadaan',
      },
    },
    {
      thClass: 'text-center',
      label: 'Kriteria Belanja',
      field: 'jenis_pengadaan',
    },
    {
      thClass: 'text-center',
      label: 'Nama Kegiatan',
      field: 'nama_kegiatan',
      filterOptions: {
        enabled: true,
        trigger: 'enter',
        placeholder: 'Nama Program',
      },
    },
    {
      thClass: 'text-center',
      label: 'Total Anggaran',
      field: 'anggaran',
      type: 'number',
    },
    {
      thClass: 'text-center',
      label: 'Tanggal Permohonan',
      field: 'last_update',
    },
    {
      thClass: 'text-center',
      label: 'Hari Dalam Antrian',
      field: 'verifikasi_teknis',
    },
    {
      thClass: 'text-center',
      label: 'DISKOMINFO',
      field: 'is_diskominfo_done',
      width: '120px',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'ORTALA',
      field: 'is_ortala_done',
      width: '120px',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Aktivitas',
      width: '85px',
      field: 'aktivitas',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Status',
      field: 'status_text',
      width: '140px',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Action',
      field: 'action',
      width: '120px',
      sortable: false,
    },
  ]

  const subColumns = [
    {
      thClass: 'text-center',
      label: 'Domain',
      field: 'domain',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Nama',
      field: 'nama',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Total Anggaran',
      field: 'anggaran',
      type: 'number',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Jenis Belanja',
      field: 'jenis_belanja',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Jenis Pengadaan',
      field: 'jenis_pengadaan',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Sumber Dana',
      field: 'sumber_dana',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Status',
      width: '140px',
      field: 'status',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Action',
      field: 'action',
      width: '100px',
      sortable: false,
    },
  ]

  return {
    oldColumns,
    newColumns,
    subColumns,
  }
}
