<template>
  <div class="list-summary-rekomendasi-awal">
    <template v-if="rekapLanjut.length !== 0">
      <h5 class="mt-1 font-weight-normal">
        Dilanjutkan
      </h5>
      <b-list-group
        class="mb-1"
        flush
      >
        <b-list-group-item
          v-for="(data, index) in rekapLanjut"
          :key="index"
          class="d-flex justify-content-between"
        >
          {{ data.recommendation }}
          <div
            style="width:120px"
            class="text-right"
          >
            <b-badge
              v-for="(people, keyd) in data.author"
              :key="keyd"
              v-b-tooltip.hover
              variant="light-info"
              class="ml-25"
              size="sm"
              :title="people"
            >
              {{ findInitials(people) }}
            </b-badge>
          </div>
        </b-list-group-item>
      </b-list-group>
    </template>

    <template v-if="rekapTidak.length !== 0">
      <h5 class="mt-1 font-weight-normal">
        Tidak Dilanjutkan
      </h5>
      <b-list-group
        class="mb-1"
        flush
      >
        <b-list-group-item
          v-for="(data, index) in rekapTidak"
          :key="index"
          class="d-flex justify-content-between"
        >
          {{ data.recommendation }}
          <div
            style="width:140px"
            class="text-right"
          >
            <b-badge
              v-for="(people, keyd) in data.author"
              :key="keyd"
              v-b-tooltip.hover
              variant="light-info"
              class="ml-25"
              size="sm"
              :title="people"
            >
              {{ findInitials(people) }}
            </b-badge>
          </div>
        </b-list-group-item>
      </b-list-group>
    </template>

    <template v-if="rekapLainnya.length !== 0">
      <h5 class="mt-1 font-weight-normal">
        Domain Non-Aplikasi / Infrastruktur
      </h5>
      <b-list-group flush>
        <b-list-group-item
          v-for="(data, index) in rekapLainnya"
          :key="index"
          class="d-flex justify-content-between"
        >
          {{ data.recommendation }}
          <div
            style="width:160px"
            class="text-right"
          >
            <b-badge
              v-for="(people, keyd) in data.people"
              :key="keyd"
              v-b-tooltip.hover
              variant="light-info"
              class="ml-25"
              size="sm"
              :title="people"
            >
              {{ findInitials(people) }}
            </b-badge>
          </div>
        </b-list-group-item>
      </b-list-group>
    </template>
  </div>
</template>

<script>
import {
  BListGroup, BListGroupItem, BBadge, VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    appDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      rekapLanjut: [],
      rekapTidak: [],
      rekapLainnya: [],
    }
  },
  mounted() {
    if (this.appDetail) {
      if (this.appDetail.continue) {
        const keys = []
        this.rekapLanjut = []
        this.appDetail.continue.map(value => {
          if (keys.indexOf(value.id) === -1) {
            keys.push(value.id)
            const reko = {
              recommendation: value.recommendation,
              author: [value.author],
            }
            this.rekapLanjut.push(reko)
          }
          const lrlAuth = this.rekapLanjut[keys.indexOf(value.id)].author

          if (lrlAuth.indexOf(value.author) === -1) {
            this.rekapLanjut[keys.indexOf(value.id)].author.push(value.author)
          }
          return true
        })
      }
      if (this.appDetail.postpone) {
        const keys = []
        this.rekapTidak = []
        this.appDetail.postpone.map(value => {
          if (keys.indexOf(value.id) === -1) {
            keys.push(value.id)
            const reko = {
              recommendation: value.recommendation,
              author: [value.author],
            }
            this.rekapTidak.push(reko)
          }
          const lrlAuth = this.rekapTidak[keys.indexOf(value.id)].author

          if (lrlAuth.indexOf(value.author) === -1) {
            this.rekapTidak[keys.indexOf(value.id)].author.push(value.author)
          }
          return true
        })
      }
    }
  },
  methods: {
    findInitials(input) {
      return input.split(' ').map(item => item.toUpperCase().substring(0, 1)).join('')
    },
  },
}
</script>
